import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
const FilePond = vueFilePond(FilePondPluginFileValidateType);
import {getJurisdictionName} from "@shared/light-ray/utils.js";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {DateTime} from "luxon";
import Tags from "@/components/tags";
import Tag from "@/components/tag";
import Button from "@shared/components/button";
import Input from "@/components/input";
import ExtLink from "@/components/ext-link";
import Pagination from "@/components/pagination";
import {EventBus} from "@/main.js";

import { mapGetters } from "vuex";

import NeoAnalyse from "../analyse";
import NeoLoader from "@/components/loader";
import {get, startCase} from "lodash";
import openCorporates from "@shared/tools/company-identifier/openCorporates";
import clarifiedBy from "@shared/tools/company-identifier/clarifiedBy";
import companyWatch from "@shared/tools/company-identifier/companyWatch";
import bvd from "@shared/tools/company-identifier/bvd";
import personBvd from "@shared/tools/company-identifier/person-bvd";
import highlighterMixin from "@shared/mixins/highlighterMixin";

export default {
    name: "corporate-records-more",
    components: {
        FilePond,
        "neo-datepicker": DatePicker,
        "neo-tags": Tags,
        "neo-tag": Tag,
        "neo-button": Button,
        "neo-input": Input,
        "neo-ext-link": ExtLink,
        "neo-pagination": Pagination,
        NeoAnalyse,

        NeoLoader,
        openCorporates,
        clarifiedBy,
        companyWatch,
        bvd,
        personBvd,
    },
    mixins: [highlighterMixin],
    props: {
        companyDetails: {
            type: Object,
            default: () => {},
        },
        fieldType: Object,
        searchedQueryKeyword: "",
        selectedQuery: {
            type: Object,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    
    data() {
        return {
            startCase: startCase,
            requireHeading: false,
            flags: [],
            finalTags: [],
            uploadedFiles: [],
            savedFile: "",
            imageCaption: "",
            postingDate: "",
            originalFile: null,
            filterKeyword: "",
            filterKeywordFinal: "",
            totalPageCount: 0,
            currentPage: 1,
            stabilizing: false,
            selectedSource: "",
            isPrintLoader: false,
            selectedValue: "",
            showSmartAnalyser: false,
            isMonitoringTab: false,
            printDataContent: {
                id: "printDataSection",
                closeCallback(vue) {
                    window.document.title = vue.windowTitle;
                },
            },
            windowTitle: window.document.title,
            fullview: false,
            tabs: [
                {
                    name: "Current",
                    value: "current",
                },
                {
                    name: "Old",
                    value: "old",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["getNeoCaseId"]),

        socialData() {
            return this.$store.getters.getMatchingSocialData;
        },
        socialContent() {
            let data = this.$store.getters.getMatchingSocialData;
            let filteredData = [];
            if (data) {
                if (this.filterKeywordFinal == "") {
                    filteredData = data.data;
                    // return data.data
                } else {
                    filteredData = data.data.filter((tweet) => {
                        if (tweet.content.toLowerCase().includes(this.filterKeywordFinal.toLowerCase())) return tweet;
                    });
                }
            }

            let count = filteredData.length / 5;
            if (count > Math.floor(count)) {
                count = Math.floor(count) + 1;
            }

            this.totalPageCount = count;
            return filteredData.slice(this.currentPage * 5 - 5, this.currentPage * 5);
        },
        getTotalPageCount() {
            return this.totalPageCount;
        },
      
        selectedTab() {
            return this.isOldVersionSelected ? "old" : "current";
        },
    },
    watch: {
        // companyDetails() {
        //     setTimeout(() => {
        //         this.highlightChanges();
        //     }, 100);
        // },
    },
    mounted() {
        if (this.isComparisonModeOn && this.companyDetails) {
          
            setTimeout(async () => {
                this.companyDetails.detailsExpanded = false;
                this.companyDetails.isOldVersion = !this.companyDetails.is_parent;
                const details = {...this.companyDetails, handleExpandAllFlag: true};
                await this.fetchMoreInfo(details, this.companyDetails.key, false);
                
                if (this.$refs.fetchMoreData) {
                  
                    await this.fetchMoreInfo(details, this.companyDetails.key, false);
                } else if (this.companyDetails.key === "officer_companyWatch") {
                    if (this.$refs?.refcompanyWatch) await this.fetchMoreInfo(details, "CurrentAppointments", false);
                }
                // await this.handleExpandAll(true, false);
                // await this.updateExpandAll(true, false);
                this.highlightChanges();
            }, 100);
        }
    },

    methods: {
        get,
        getJurisdictionName,

        getIdentifierMapping(key) {
            let mapping = "";
            switch (this.companyDetails.key) {
                case "openCorporates":
                    if (key === "company_name") mapping = "['data']['name']";
                    else if (key === "company_number") mapping = "['data']['company_number']";
                    else if (key === "company_type") mapping = "['data']['company_type']";
                    else if (key === "address") mapping = "['data']['registered_address_in_full']";
                    else if (key === "jurisdiction") mapping = "['data']['jurisdiction_code']";
                    else if (key === "incorporation_date") mapping = "['data']['incorporation_date']";
                    break;
                case "clarifiedBy":
                    if (key === "company_name") mapping = "['data']['company_name']['name_en']";
                    else if (key === "company_number") mapping = "['data']['legal_form']['name_en']";
                    else if (key === "company_type") mapping = "['data']['company_type']";
                    else if (key === "address") mapping = "['data']['city_of_registration']['name_en'] ['data']['country_of_registration']['name_en']";
                    else if (key === "jurisdiction") mapping = "['data']['country_code']";
                    else if (key === "incorporation_date") mapping = "['data']['incorporation_date']";
                    break;
                case "companyWatch":
                    if (key === "company_name") mapping = "['data']['name']";
                    else if (key === "company_number") mapping = "['data']['nationalID']";
                    else if (key === "company_type") mapping = "";
                    else if (key === "address") mapping = "['data']['address']";
                    else if (key === "jurisdiction") mapping = "['data']['isoCountry']";
                    else if (key === "incorporation_date") mapping = "['data']['incorporation_date']";
                    break;
                case "bvd":
                    if (key === "company_name") mapping = "['data']['NAME']";
                    else if (key === "company_number") mapping = "['data']['COMPANY_ID_NUMBER'][0]";
                    else if (key === "company_type") mapping = "['data']['NATIONAL_LEGAL_FORM']";
                    else if (key === "address") mapping = "['data']['ADDRESS_LINE1'] ['data']['ADDRESS_LINE2'] ['data']['ADDRESS_LINE3'] ['data']['ADDRESS_LINE4'] ['data']['CITY'] ['data']['COUNTRY']";
                    else if (key === "jurisdiction") mapping = "['data']['COUNTRY_ISO_CODE']";
                    else if (key === "incorporation_date") mapping = "['data']['INCORPORATION_DATE']";
                    break;
                case "officer":
                    if (key === "company_name") mapping = "['data']['officer']['company']['name']";
                    else if (key === "company_number") mapping = "['data']['officer']['company']['company_number']";
                    else if (key === "company_type") mapping = "['data']['officer']['company_type']";
                    else if (key === "address") mapping = "['data']['officer']['address']";
                    else if (key === "jurisdiction") mapping = "['data']['officer']['jurisdiction_code']";
                    else if (key === "incorporation_date") mapping = "['data']['officer']['incorporation_date']";
                    break;
                case "officer_clarifiedBy":
                    if (key === "company_name") mapping = "['data']['officer']['company']['name']";
                    else if (key === "company_number") mapping = "['data']['officer']['company']['company_number']";
                    else if (key === "company_type") mapping = "['data']['officer']['company_type']";
                    else if (key === "address") mapping = "['data']['officer']['address']";
                    else if (key === "jurisdiction") mapping = "['data']['officer']['jurisdiction_code']";
                    else if (key === "incorporation_date") mapping = "['data']['officer']['incorporation_date']";
                    break;
                case "officer_companyWatch":
                    if (key === "company_name") mapping = "['data']['officer']['company']['name']";
                    else if (key === "company_number") mapping = "['data']['officer']['company']['company_number']";
                    else if (key === "company_type") mapping = "['data']['officer']['company_type']";
                    else if (key === "address") mapping = "['data']['officer']['address']";
                    else if (key === "jurisdiction") mapping = "['data']['productCode']";
                    else if (key === "incorporation_date") mapping = "['data']['officer']['incorporation_date']";
                    break;
                case "officer_bvd":
                    if (key === "company_name") mapping = "['data']['CONTACTS_MEMBERSHIP_NameCompany']";
                    else if (key === "company_number") mapping = "['data']['CONTACTS_MEMBERSHIP_IdCompany']";
                    else if (key === "company_type") mapping = "['data']['officer']['company_type']";
                    else if (key === "address") mapping = "['data']['officer']['address']";
                    else if (key === "jurisdiction") mapping = "['data']['officer']['jurisdiction_code']";
                    else if (key === "incorporation_date") mapping = "['data']['CONTACTS_MEMBERSHIP_BeginningNominationDate']";
                    break;
                case "address":
                    if (key === "company_name") mapping = "['data']['name']";
                    else if (key === "company_number") mapping = "['data']['company_number']";
                    else if (key === "company_type") mapping = "['data']['company_type']";
                    else if (key === "address") mapping = "['data']['registered_address_in_full']";
                    else if (key === "jurisdiction") mapping = "['data']['jurisdiction_code']";
                    else if (key === "incorporation_date") mapping = "['data']['incorporation_date']";
                    break;
            }
            return mapping;
        },

        getPreviousNames(detail) {
            return detail?.api_all_data?.company?.previous_names?.map((item) => item.company_name) || [];
        },

        checkPreviousNames(detail) {
            return detail.company_name && !detail.company_name.toLowerCase().includes(this.searchedQueryKeyword?.toLowerCase()) && this.getPreviousNames(detail).length > 0;
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            return DateTime.fromISO(dt).toFormat(format);
        },
        formatDate(dt) {
          
            return dt;
        },
        commaJoin(...values) {
            return values.filter((e) => e != null && e != "").join(", ");
        },
        joinArray(arr, sep = ", ") {
            return arr.filter((e) => e != null && e != "").join(sep);
        },
        klReady(chart) {
            this.chart = chart;
            this.initialiseInteractions();
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        initialiseInteractions() {
            // this.chart.on("context-menu", ({ id }) => {
            //     this.showSmartAnalyser = true
            //     const node = this.chart.getItem(id);
            //     var viewCoords = this.chart.viewCoordinates(node.x, node.y)
            //     var fakeStartButton = this.$refs['analyze-popup']['$el']
            //     fakeStartButton.style.position = "absolute"
            //     fakeStartButton.style.left = `${viewCoords.x + 300}px`
            //     fakeStartButton.style.top = `${viewCoords.y}px`
            //     this.selectedValue = node.label
            //     // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
            //     if (node.nodeType === 'link') {
            //         this.selectedSource = 'url'
            //     }
            //     else if (node.nodeType === 'company') {
            //         this.selectedSource = 'company_name'
            //     }
            //     else {
            //         this.selectedSource = node.nodeType
            //     }
            //     this.$refs['analyze-popup'].$el.click()
            //     // e.preventDefault();
            //     // this.resetAnalyzerOptions()
            // });
        },
        chainDataCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity?.filter((n) => n.owner).length && companyDetails?.company?.chain_data?.entity?.filter((n) => n.ultimate_beneficiary).length;
        },
        graphCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity.length > 0 && companyDetails?.company?.chain_data?.entity.length > 0;
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
        moreInfoCheck(companyDetails) {
            return companyDetails?.fetched ? true : false;
        },
        async fetchMoreInfo(details, api_call_type = null, recall = true) {
            console.log(details)
            details[`${api_call_type}Loading`] = true;
            details[`isLoading`] = true;
            this.$forceUpdate();
            await EventBus.$emit("showMoreData", {...details, api_call_type});
            console.log("ijjj")
            details[`${api_call_type}Loading`] = false;
            this.$forceUpdate();
            if (this.isComparisonModeOn && recall) {
                console.log("inside fetch more")
                const otherVersion = this.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion.fetchMoreInfo(otherVersion.companyDetails, api_call_type, false);
            }

            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                    this.$forceUpdate();
                }, 100);
       
            // details.expanded = !details.expanded;
        },
        updatePrintStatus() {
            this.isPrintLoader = !this.isPrintLoader;
        },
        async printPage(id, filename, type) {
            this.isPrintLoader = true;
            if (this.$refs.fetchMoreData) {
                // this.$refs.fetchMoreData.click();
                await this.fetchMoreInfo(this.companyDetails, this.companyDetails.key, false);
            }
            // setTimeout(() => {
            this.$refs[type]?.getPrintdata(id, filename);
            // }, 2000);
        },
        handlePrint() {
            // ('printDataSection',companyDetails.company_name)
            const {key, sourceTag, company_name} = this.companyDetails;
            if (key === "openCorporates" || key === "address" || (key === "officer" && sourceTag === "openCorporates")) {
                this.printPage("printDataSection", company_name, "companyCorporaters");
            } else if (key === "clarifiedBy" && sourceTag === undefined) {
                this.printPage("printDataSection", company_name, "refClirifiedByme");
            } else if (key === "officer_clarifiedBy" && sourceTag === "clarifiedBy") {
                this.printPage("printDataSection", this.companyDetails.api_all_data?.subject_name?.name_en ?? "", "refClirifiedByme");
            } else if (key === "companyWatch") {
                this.printPage("printDataSection", company_name, "refcompanyWatch");
            } else if (key === "officer_companyWatch") {
                this.printPage("printDataSection", this.companyDetails.api_all_data.directorName, "refcompanyWatch");
            } else if (key === "bvd" || key === "officer_bvd") {
                this.printPage("printDataSection", company_name, "companyBvd");
            }
        },
        printDocument(filename) {
            this.windowTitle = window.document.title;
            window.document.title = filename;
            this.$refs.printDataSectionBtn.click();
        },
        async handleExpandAll(value, recall = true) {
            let key = this.companyDetails.key;
            if (value != undefined) {
                await EventBus.$emit("handleExpandAllPanel", {
                    details: this.companyDetails,
                    key: key,
                    value: value,
                });
            } else {
                await EventBus.$emit("handleExpandAllPanel", {
                    details: this.companyDetails,
                    key: key,
                });
            }
            if (this.isComparisonModeOn && recall) {
                const otherVersion = this.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion.handleExpandAll(value, false);
            }

            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                    this.$forceUpdate();
                }, 100);
        
            this.$forceUpdate();
        },
        async updateExpandAll(value, recall = true) {
            this.companyDetails.expandedPanel = value;
            this.$forceUpdate();
            if (this.isComparisonModeOn && recall) {
                const otherVersion = this.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion.updateExpandAll(value, false);
            }

            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                    this.$forceUpdate();
                }, 100);
        },
        async handleFetchReport(bvd_id) {
            document.body.classList.add("modal-open");
            this.$modal.show("report_modal");
            await EventBus.$emit("fetchReport", {
                details: this.companyDetails,
                id: bvd_id,
            });
        },

        closeReport() {
            document.body.classList.remove("modal-open");
        },
        handleResultSaveData(result) {
            EventBus.$emit("handleResultCorporateSave", result);
        },
        onFullScreen(reset = false) {
            let elem = document.getElementById("corporate-records-more");
            if (reset) {
                elem.style.setProperty("width", "60%", "important");
            } else {
                elem.style.width = null;
            }
            if (elem) {
                elem.style.position = reset ? null : "absolute";
                elem.style.inset = reset ? null : "0";
                elem.style.zIndex = reset ? null : "21";
                elem.style.backgroundColor = reset ? null : "#fff";
            }

            let resElem = document.getElementById("printDataSection");
            if (resElem) {
                resElem.style.padding = reset ? "0 0.25rem" : 0;
                resElem.style.borderRadius = reset ? "0.375rem" : 0;
            }

            let buttonToolbar = document.getElementById("button-toolbar");
            if (buttonToolbar) buttonToolbar.style.display = reset ? null : "none"; // 25

            let osintExpand = document.getElementById("osint--panel--drawer");
            if (osintExpand) osintExpand.style.display = reset ? null : "none"; //2020

            // let versioningTabSwitch = document.getElementById("monitoring-version-switch")
            // if (versioningTabSwitch)
            //     versioningTabSwitch.style.display = reset ? null : "none"; //20

            let monitoringDateRangeSlider = document.getElementsByClassName("monitoring-dateRange-slider");
            if (monitoringDateRangeSlider?.[0]) monitoringDateRangeSlider[0].style.display = reset ? null : "none"; //20

            this.fullview = !reset;
        },
        onClose() {
            if (this.fullview) {
                this.onFullScreen(true);
            } else {
                this.$emit("closeMoreInfo");
            }
        },
    },
};
