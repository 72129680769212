import {DateTime} from "luxon";
import NeoLoader from "@/components/loader";
import Tags from "@/components/tags";
import Tag from "@/components/tag";
import Tab from "@/components/tab";
import nLoader from "@/components/n-loader";
import {EventBus} from "@/main.js";
import printDataMixin from "@shared/tools/mixins/printDataMixin";
import { AddNewEntityButton } from "@shared/case-entities";
import highlighterMixin from "@shared/mixins/highlighterMixin";

import {get, startCase} from "lodash";

export default {
    name: "bvd",

    components: {
        NeoLoader,
        "neo-tags": Tags,
        "neo-tag": Tag,
  
        "neo-tab": Tab,
        nLoader,
        AddNewEntityButton,
    },
    mixins: [printDataMixin, highlighterMixin],
    props: {
        companyDetails: {type: Object},
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tabs: [
                {
                    key: "company_details",
                    name: "Company Details",
                    enabled: true,
                },
                {
                    key: "full_report",
                    name: "Full Report",
                    enabled: true,
                },
            ],
            currentTab: "company_details",
            startCase: startCase,
            stabilizing: false,
            refBtnClick: {
                moreCompanyProfileInput: true,
                moreCompanyContactInput: true,
                moreCompanyProviderInput: true,
                moreCompanyShareHolderInput: true,
                moreCompanyBeneficiariesInput: true,
                moreCompanyInformationInput: true,
                moreCompanySubsidiariesInput: true,
            },
        };
    },

    computed: {
        shareCheck() {
            return this.companyDetails?.api_all_data?.SHAREHOLDERS && Array.isArray(this.companyDetails.api_all_data.SHAREHOLDERS) ? !this.companyDetails?.api_all_data?.SHAREHOLDERS?.every((e) => this.isEmptyObject(e)) : false;
        },
        shareICheck() {
            return this.companyDetails?.api_all_data?.SHAREHOLDER_INFORMATION && Array.isArray(this.companyDetails.api_all_data.SHAREHOLDER_INFORMATION) ? !this.companyDetails?.api_all_data?.SHAREHOLDER_INFORMATION?.every((e) => this.isEmptyObject(e)) : false;
        },
        shareGICheck() {
            return this.companyDetails?.api_all_data?.SHAREHOLDERS_GENERAL_INFORMATION && Array.isArray(this.companyDetails.api_all_data.SHAREHOLDERS_GENERAL_INFORMATION) ? !this.companyDetails?.api_all_data?.SHAREHOLDERS_GENERAL_INFORMATION?.every((e) => this.isEmptyObject(e)) : false;
        },
        shareCICheck() {
            return this.companyDetails?.api_all_data?.CONTROLLING_SHAREHOLDERS && Array.isArray(this.companyDetails.api_all_data.CONTROLLING_SHAREHOLDERS) ? !this.companyDetails?.api_all_data?.CONTROLLING_SHAREHOLDERS?.every((e) => this.isEmptyObject(e)) : false;
        },
        subSidiaryCheck() {
            // !Array.isArray(this.companyDetails.api_all_data.SUBSIDIARIES_INFO)
            return this.companyDetails?.api_all_data?.SUBSIDIARIES_INFO && Array.isArray(this.companyDetails.api_all_data.SUBSIDIARIES_INFO) ? !this.companyDetails?.api_all_data?.SUBSIDIARIES_INFO?.every((e) => this.isEmptyObject(e)) : false;
        },
        otherBeneficCheck() {
            return this.companyDetails?.api_all_data?.OTHER_ULTIMATE_BENEFICIARIES && Array.isArray(this.companyDetails.api_all_data.OTHER_ULTIMATE_BENEFICIARIES) ? !this.companyDetails?.api_all_data?.OTHER_ULTIMATE_BENEFICIARIES?.every((e) => this.isEmptyObject(e)) : false;
        },
        otherUltimateBen() {
            return this.companyDetails?.api_all_data?.OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES && Array.isArray(this.companyDetails.api_all_data.OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES) ? !this.companyDetails?.api_all_data?.OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES?.every((e) => this.isEmptyObject(e)) : false;
        },
        benefecRegCheck() {
            return this.companyDetails?.api_all_data?.BENEFICIAL_OWNERS_FROM_REGISTER && Array.isArray(this.companyDetails.api_all_data.BENEFICIAL_OWNERS_FROM_REGISTER) ? !this.companyDetails?.api_all_data?.BENEFICIAL_OWNERS_FROM_REGISTER?.every((e) => this.isEmptyObject(e)) : false;
        },
        immediateParents() {
            return this.companyDetails?.api_all_data?.IMMEDIATE_PARENT_INFO && Array.isArray(this.companyDetails.api_all_data.IMMEDIATE_PARENT_INFO) ? !this.companyDetails?.api_all_data?.IMMEDIATE_PARENT_INFO?.every((e) => this.isEmptyObject(e)) : false;
        },
        globalOwnerCheck() {
            // !Array.isArray(this.companyDetails.api_all_data.GLOBAL_ULTIMATE_OWNER_INFO)
            return this.companyDetails?.api_all_data?.GLOBAL_ULTIMATE_OWNER_INFO && Array.isArray(this.companyDetails.api_all_data.GLOBAL_ULTIMATE_OWNER_INFO) ? !this.companyDetails?.api_all_data?.GLOBAL_ULTIMATE_OWNER_INFO?.every((e) => this.isEmptyObject(e)) : false;
        },
    },

    mounted() {
        this.highlightChanges();
    },

    methods: {
        get,
        getIdentifierMapping(key, idx, data) {
            let mapping = "";
            const length = data?.length;
            if (key === "address")
                mapping = `['details']['ADDRESS_LINE1'] ['details']['ADDRESS_LINE2'] ['details']['ADDRESS_LINE3'] ['details']['ADDRESS_LINE4']
                ['details']['CITY'] ['details']['POSTCODE']`;
            else if (key === "SH_address")
                mapping = `['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_ADDRESS_LINE1'] ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_ADDRESS_LINE2']
                ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_ADDRESS_LINE3'] ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_ADDRESS_LINE4']
                ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_CITY'] ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_COUNTRY_ISO_CODE'] ['details']['SHAREHOLDER_INFORMATION'][${idx}]['SH_POSTCODE']`;
            else if (key === "CSH_address") mapping = `['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['CSH_CITY'] ['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['CSH_STATE_PROVINCE'] ['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['CSH_COUNTRY_ISO_CODE']`;
            else if (key === "OUB_address") mapping = `['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['OUB_ADDRESS'] ['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['OUB_CITY'] ['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['OUB_COUNTRY_ISO_CODE'] ['details']['CONTROLLING_SHAREHOLDERS'][${idx}]['OUB_POSTCODE']`;
            else if (key === "OUBI_address")
                mapping = `['details']['OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES'][0]['OUBI_ADDRESS'][${idx}] ['details']['OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES'][0]['OUBI_COUNTRY_ISO_CODE'][${idx}]
                ['details']['OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES'][0]['OUBI_CITY'][${idx}] ['details']['OTHER_ULTIMATE_BENEFICIARIES_INTERMEDIARIES'][0]['OUBI_POSTCODE'][${idx}]`;
            else if (key === "ISH_address") mapping = `['details']['IMMEDIATE_PARENT_INFO'][${idx}]['ISH_CITY'] ['details']['IMMEDIATE_PARENT_INFO'][${idx}]['ISH_STATE_PROVINCE'] ['details']['IMMEDIATE_PARENT_INFO'][${idx}]['ISH_COUNTRY_ISO_CODE']`;
            else if (key === "ISH_salutation") mapping = `['details']['IMMEDIATE_PARENT_INFO'][${idx}]['ISH_SALUTATION'] ['details']['IMMEDIATE_PARENT_INFO'][${idx}]['ISH_FIRST_NAME']`;
            else if (key === "GUO_address") mapping = `['details']['GLOBAL_ULTIMATE_OWNER_INFO'][${idx}]['GUO_CITY'] ['details']['GLOBAL_ULTIMATE_OWNER_INFO'][${idx}]['GUO_STATE_PROVINCE'] ['details']['GLOBAL_ULTIMATE_OWNER_INFO'][${idx}]['GUO_COUNTRY_ISO_CODE']`;
            else if (key === "SUB_address")
                mapping = `['details']['SUBSIDIARIES_INFO'][${idx}]['SUB_ADDRESS_LINE1'] ['details']['SUBSIDIARIES_INFO'][${idx}]['SUB_ADDRESS_LINE2'] 
                ['details']['SUBSIDIARIES_INFO'][${idx}]['SUB_ADDRESS_LINE3'] ['details']['SUBSIDIARIES_INFO'][${idx}]['SUB_ADDRESS_LINE4'] 
                ['details']['SUBSIDIARIES_INFO'][${idx}]['SUB_POSTCODE']`;
            else if (key === "DOMAIN" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['DOMAIN'][${i}] `;
            } else if (key === "WEBSITE" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['WEBSITE'][${i}] `;
            } else if (key === "COMPANY_ID_NUMBER" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['COMPANY_ID_NUMBER'][${i}] `;
            } else if (key === "TRADE_REGISTER_NUMBER" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['TRADE_REGISTER_NUMBER'][${i}] `;
            } else if (key === "INDUSTRY_PRIMARY_LABEL" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['INDUSTRY_PRIMARY_LABEL'][${i}] `;
            } else if (key === "PHONE_NUMBER" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['PHONE_NUMBER'][${i}] `;
            } else if (key === "VAT_NUMBER" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['VAT_NUMBER'][${i}] `;
            } else if (key === "FAX_NUMBER" && length) {
                for (let i = 0; i < length; i++) mapping += `['details']['FAX_NUMBER'][${i}] `;
            }

            return mapping;
        },

        getAnalyserSource(key, matchKeyword, matchSource) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return matchSource || "name";
            else return matchSource ? "name" : "company_name";
        },
        commaJoin(...values) {
            let value = values.filter((e) => e != null && e != "").join(", ");
            if (value === " " || value === "") return "-";
            else return value;
        },
        joinArray(arr, sep = ", ") {
            return arr.filter((e) => e != null && e != "").join(sep);
        },
        formatDateFromISO(dt, format = "d MMMM yyyy") {
            return DateTime.fromISO(dt).toFormat(format);
        },
        async fetchMoreInfo(details, api_call_type = null) {
            //this.handlePanelExpand(details, api_call_type);
            this.$emit("fetchMoreInfo", details, api_call_type);
        },
        handlePanelExpand(details, type, recall = true) {
            details[`${type}Expanded`] = !details[`${type}Expanded`];

            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.companyDetails.api_all_data.doc_id !== this.companyDetails.api_all_data.doc_id);
                otherVersion = otherVersion.$children[otherVersion.$children.length - 1];
                otherVersion.handlePanelExpand(otherVersion.companyDetails, type, false);
            }

            if (
                details.CompanyProfileExpanded &&
                details.ContactExpanded &&
                (details.InformationProviderExpanded || !(details && details.api_all_data && details.api_all_data.INFORMATION_PROVIDER && details.api_all_data.INFORMATION_PROVIDER.length)) &&
                (details.ShareholdersExpanded || !(this.shareGICheck || this.shareCheck || this.shareICheck || this.shareCICheck)) &&
                (details.BeneficiariesExpanded || !(this.otherBeneficCheck || this.otherUltimateBen || this.benefecRegCheck)) &&
                (details.OwnersExpanded || !(this.globalOwnerCheck || this.immediateParents)) &&
                (details.SubsidiariesExpanded || !this.subSidiaryCheck) &&
                (details.directorsExpanded || !(details.api_all_data && details.api_all_data.DIRECTORS && Array.isArray(details.api_all_data.DIRECTORS)))
            ) {
                details.expandedPanel = true;
            } else {
                details.expandedPanel = false;
            }
            this.$emit("updateExpandAll", details.expandedPanel);
            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                }, 100);
            this.$forceUpdate();
        },
        klReady(chart) {
            this.chart = chart;
            this.initialiseInteractions();
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        async expandAll() {
            await EventBus.$emit("handleExpandAllPanel", {
                details: this.companyDetails,
                key: "bvd",
            });
        },
        initialiseInteractions() {
            // this.chart.on("context-menu", ({ id }) => {
            //     this.showSmartAnalyser = true
            //     const node = this.chart.getItem(id);
            //     var viewCoords = this.chart.viewCoordinates(node.x, node.y)
            //     var fakeStartButton = this.$refs['analyze-popup']['$el']
            //     fakeStartButton.style.position = "absolute"
            //     fakeStartButton.style.left = `${viewCoords.x + 300}px`
            //     fakeStartButton.style.top = `${viewCoords.y}px`
            //     this.selectedValue = node.label
            //     // this.selectedSource = (node.nodeType === 'link') ?  'url' ? (node.nodeType === 'company')  : node.nodeType
            //     if (node.nodeType === 'link') {
            //         this.selectedSource = 'url'
            //     }
            //     else if (node.nodeType === 'company') {
            //         this.selectedSource = 'company_name'
            //     }
            //     else {
            //         this.selectedSource = node.nodeType
            //     }
            //     this.$refs['analyze-popup'].$el.click()
            //     // e.preventDefault();
            //     // this.resetAnalyzerOptions()
            // });
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
        isEmptyObject(o) {
            return Object.keys(o).every((key) => {
                return o[key] === "" || o[key] === null; // or just "return o[x];" for falsy values
            });
        },
        onTabChange(tab) {
            if (this.currentTab === tab) return;
            else this.currentTab = tab;
        },
        async getPrintdata(id, filename) {
            this.currentTab = "company_details";
            await this.$emit("handleExpandAllPanel", {
                details: this.companyDetails,
                key: "bvd",
                value: true,
            });
            // setTimeout(() => {
            this.$emit("updatePrintStatus", "");
            this.printData(id, filename);
        },

        showAddEntityFlow(temporaryName) {
            this.$store.commit("SET_SHOW_ADD_NEW_ENTITY", true);
            this.$store.commit("SET_NEW_ENTITY_INFO", { key: "temporary_name", value: temporaryName });
        },
    },
};
