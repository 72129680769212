import {mapGetters} from "vuex";
import NeoNewsExpanded from "@/components/news-expanded";
import AdverseMediaExpand from "@/components/adverse-media-expand";
import NeoCorporateRecordsMore from "@/components/corporate-records-more";

export default {
    name: "monitoring-differential-highlighter",
    components: {
        NeoNewsExpanded,
        AdverseMediaExpand,
        NeoCorporateRecordsMore,
    },
    props: {
        cardComponent: {
            type: String,
            default: "",
        },
        data: {
            type: Array,
            default: [],
        },
        meta_data: {
            type: Object,
            default: null,
        },
        differentials: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {};
    },
    computed: {
        ...mapGetters([]),
        isMonitoringTab() {
            return true;
        },
    },
};
